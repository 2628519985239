/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  useDataProvider,
  Filter,
} from 'react-admin';
import { Switch, Button } from '@material-ui/core/';
import SortIcon from '@material-ui/icons/Sort';
import { i18nProvider } from '../../../services/i18nProvider';
import { HighPagination } from '../CustomPagination/CustomPagination';
import { CustomActions } from '../CustomActions/CustomActions';
import { DroppableDatagrid } from './DroppableDatagrid';
import './PropertyList.css';

const EDIT_PROPERTIES_PATH = '/edit-order';

const languages = [
  { id: 'de_DE', name: 'app.lang_german' },
  { id: 'en_US', name: 'app.lang_english' },
];

const categories = [
  { id: 'ac', name: 'AC' },
  { id: 'dc', name: 'DC' },
];

const useStyles = makeStyles({
  sortOrder: { color: '#ffffff' },
  hiddenHeader: { color: '#ffffff' },
});

const PropertyFilter = (props) => {
  const {
    filterValues,
  } = props;

  return (
    <Filter {...props}>
      <SelectInput
        source="language"
        choices={languages}
        initialValue={filterValues.language}
        alwaysOn
      />
      <SelectInput
        source="category"
        choices={categories}
        initialValue={filterValues.category}
        alwaysOn
      />
    </Filter>
  );
};

const PropertiesActions = (props) => {
  const { translate: t } = i18nProvider;
  return (
    <CustomActions {...props}>
      <Button
        startIcon={<SortIcon />}
        href={`#${props.basePath}${EDIT_PROPERTIES_PATH}`}
      >
        {t('app.editOrder')}
      </Button>
    </CustomActions>
  );
};

const ActiveField = (props) => {
  const {
    record,
    source,
  } = props;
  const {
    id,
  } = record;

  const dataProvider = useDataProvider();
  const value = record[source];
  const [isSet, setValue] = React.useState(value);

  const handleActiveChange = () => {
    const nextValue = !isSet;
    setValue(nextValue);

    dataProvider.update('properties', {
      id,
      data: {
        ...record,
        [source]: nextValue,
      },
    });
  };

  return (
    <Switch
      checked={!!isSet}
      onChange={handleActiveChange}
    />
  );
};

const NormalList = props => (
  <List
    filters={<PropertyFilter {...props} />}
    filterDefaultValues={{
      language: languages[0].id,
      category: categories[0].id,
    }}
    {...props}
    className="PropertyList"
    pagination={<HighPagination />}
    perPage={500}
    actions={<PropertiesActions {...props} />}
  >
    <Datagrid {...props}>
      <TextField source="name" />
      <ActiveField source="filterActive" />
      <ActiveField source="premium" />
      <TextField source="type" />
    </Datagrid>
  </List>
);

export const SortableList = ({ sortProp, ...props }) => {
  const classes = useStyles();
  return (
    <List
      style={{ maxWidth: '50%' }}
      filters={<PropertyFilter {...props} />}
      filterDefaultValues={{
        language: languages[0].id,
        category: categories[0].id,
      }}
      {...props}
      pagination={<HighPagination />}
      perPage={500}
      actions={<CustomActions />}
      bulkActionButtons={false}
    >
      <DroppableDatagrid>
        <TextField source="name" />
        <TextField
          source={sortProp}
          className={classes.sortOrder}
          headerClassName={classes.hiddenHeader}
        />
      </DroppableDatagrid>
    </List>
  );
};

export const PropertyList = props => (
  <Router>
    <Route
      exact
      path={props.basePath}
      render={() => (
        <NormalList sortProp="sortOrder" {...props} />
      )}
    />
    <Route
      exact
      path={props.basePath + EDIT_PROPERTIES_PATH}
      render={() => (
        <SortableList
          sortProp="sortOrder"
          {...props}
        />
      )}
    />
  </Router>
);

PropertyList.propTypes = {
  basePath: PropTypes.string.isRequired,
};
PropertiesActions.propTypes = PropertyList.propTypes;
