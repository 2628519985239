import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Pagination,
} from 'react-admin';

export const AccountList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    perPage={25}
    pagination={
      <Pagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        {...props}
        perPage={25}
      />
    }
  >
    <Datagrid rowClick="show">
      <TextField source="organizationName" sortable={false} />
      <TextField source="servicePackage.title" sortable={false} />
      <TextField source="servicePackage.amountUsers" sortable={false} />
      <BooleanField source="active" sortable={false} />
    </Datagrid>
  </List>
);

export default AccountList;
