import React from "react";
import { List, Datagrid, TextField, EmailField } from "react-admin";
import PropTypes from "prop-types";
import { CustomActions } from "../CustomActions/CustomActions";

export const UserLogsList = (props) => (
  <List {...props} bulkActionButtons={false} actions={<CustomActions />}>
    <Datagrid rowClick="show">
      <TextField source="givenName" />
      <TextField source="familyName" />
      <EmailField source="email" />
      <TextField source="organizationName" />
    </Datagrid>
  </List>
);

UserLogsList.propTypes = {
  basePath: PropTypes.string.isRequired,
};
