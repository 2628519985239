import React from 'react';
import {BooleanField, Show, SimpleShowLayout, TextField} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {i18nProvider} from '../../../services/i18nProvider';

const useStyles = makeStyles({
  table: {
    backgroundColor: "#ebebeb",
  },
});

const CategoriesList = ({ record }) =>
  record.categories.map((category) => (
    <div key={category.id}>{category.name}</div>
  ));
CategoriesList.defaultProps = {
  addLabel: true,
};

const UserList = ({ record }) => {
  const classes = useStyles();
  const { translate: t } = i18nProvider;
  return (
    record && (
      <Table>
        <TableHead>
          <TableRow className={classes.table}>
            <TableCell>{t("app.firstName")}</TableCell>
            <TableCell>{t("app.lastName")}</TableCell>
            <TableCell>{t("app.email")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.givenName}</TableCell>
              <TableCell>{user.familyName}</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  );
};
UserList.defaultProps = {
  addLabel: true,
};

export const AccountShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="organizationName" />
      <TextField source="billingAddress.street" />
      <TextField source="billingAddress.addressSuffix" />
      <TextField source="billingAddress.postalCode" />
      <TextField source="billingAddress.city" />
      <TextField source="billingAddress.country" />
      <TextField source="servicePackage.title" />
      <CategoriesList source="categories" />
      <UserList source="users" />
      <BooleanField source="active" />
    </SimpleShowLayout>
  </Show>
);
