import React from 'react';
import {
  Login,
} from 'react-admin';
import backgroundImg from '../../../assets/photo-car-charging.jpg';
import { i18nProvider } from '../../../services/i18nProvider';

export const LoginPage = () => (
  <div className="LoginPage">
    <Login
      i18nProvider={i18nProvider}
      backgroundImage={backgroundImg}
    />
  </div>
);

export default LoginPage;
