/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Admin, Layout, Resource } from "react-admin";
import ListIcon from "@material-ui/icons/List";
import UserIcon from "@material-ui/icons/Group";
import ImageIcon from "@material-ui/icons/Image";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import { authProvider } from "../../../services/authProvider";
import {
  dataProvider,
  IMAGE_UPLOAD,
  IMPORT_FILES,
  USERS,
  ACCOUNTS,
  PROPERTIES,
  USER_LOGS,
  CATEGORIES,
  MANUFACTURERS,
  SERVICE_PACKAGES,
} from "../../../services/dataProvider";
import { i18nProvider, CHANGE_LOCALE } from "../../../services/i18nProvider";
import { LoginPage } from "../LoginPage/LoginPage";
import { UserList } from "../../Components/User/UserList";
import { UserCreate, UserEdit } from "../../Components/User/UserEdit";
import { AccountList } from "../../Components/Account/AccountList";
import { AccountShow } from "../../Components/Account/AccountShow";
import { AccountEdit } from "../../Components/Account/AccountEdit";
import { ImportFileCreate } from "../../Components/ImportFiles/ImportFileCreate";
import { ImportFileList } from "../../Components/ImportFiles/ImportFileList";
import { PropertyList } from "../../Components/Property/PropertyList";
import { CustomAppBar } from "../../Components/CustomAppBar/CustomAppBar";
import "./AdminPage.css";
import { ImageUploadCreate } from "../../Components/ImageUpload/ImageUploadCreate";
import { ImageUploadList } from "../../Components/ImageUpload/ImageUploadList";
import { ACCESS_TOKEN } from "../../../services/api";
import { UserLogsList } from "../../Components/UserLogs/UserLogsList";
import { UserLogsShow } from "../../Components/UserLogs/UserLogsShow";

export const CustomLayout = (props) => (
  <Layout {...props} appBar={CustomAppBar} />
);

export const AdminPage = () => {
  const { getLocale, translate: t } = i18nProvider;
  const [locale, setLocale] = useState(getLocale());

  const knownResources = [
    <Resource
      name={USERS}
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      icon={UserIcon}
      options={{ label: t("app.users") }}
    />,
    <Resource
      name={ACCOUNTS}
      list={AccountList}
      edit={AccountEdit}
      icon={RecentActorsIcon}
      show={AccountShow}
      options={{ label: t("app.accounts") }}
    />,
    <Resource
      name={PROPERTIES}
      list={PropertyList}
      icon={ListIcon}
      options={{ label: t("app.properties") }}
    />,
    <Resource
      name={IMPORT_FILES}
      create={ImportFileCreate}
      list={ImportFileList}
      icon={InsertDriveFile}
      options={{ label: t("app.import-files") }}
    />,
    <Resource
      name={IMAGE_UPLOAD}
      create={ImageUploadCreate}
      list={ImageUploadList}
      icon={ImageIcon}
      options={{ label: t("app.image-upload") }}
    />,
    <Resource
      name={USER_LOGS}
      list={UserLogsList}
      show={UserLogsShow}
      icon={ImportantDevicesIcon}
      options={{ label: t("app.user-logs") }}
    />,
    // do not remove manufacturers amd categories resources, needed for referenceState in UserEdit!
    <Resource name={MANUFACTURERS} />,
    <Resource name={CATEGORIES} />,
    <Resource name={SERVICE_PACKAGES} />,
  ];

  const fetchResources = () => {
    /**
     * here be dragons!
     * this is an ugly hack to prevent React-admin standard behavior of
     * using the first Resource as 'the homepage' and thus firing a GET call to
     * the respective route before the authentication call to /oauth/v2/token
     */
    const hasAccessToken = !!localStorage.getItem(ACCESS_TOKEN);
    return hasAccessToken ? knownResources : [<Resource name="login" />];
  };

  useEffect(() => {
    const handleLocale = (event) => {
      setLocale(event.detail.newLocale);
    };
    document.addEventListener(CHANGE_LOCALE, handleLocale, false);
    return () => document.removeEventListener(CHANGE_LOCALE, handleLocale);
  }, []);

  return (
    <div className="AdminPage" data-locale={locale}>
      <Admin
        authProvider={authProvider}
        loginPage={LoginPage}
        layout={CustomLayout}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
      >
        {fetchResources}
      </Admin>
    </div>
  );
};

export default AdminPage;
