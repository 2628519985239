import React from 'react';
import {
  FileInput,
  Create,
  FileField,
  SimpleForm,
  required,
} from 'react-admin';

export const ImportFileCreate = props => (
  <Create {...props} style={{ maxWidth: '50%' }}>
    <SimpleForm >
      <FileInput multiple source="files" accept=".csv" validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
