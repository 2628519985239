// https://marmelab.com/react-admin/doc/3.0/Translation.html
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { en } from '../assets/i18n/en';
import { de } from '../assets/i18n/de';


const getLocaleByBrowser = () => {
  const availableLocales = ['en', 'de'];
  const browserLanguage = navigator.language.slice(0, 2);
  let _locale = 'en';
  if (availableLocales.includes(browserLanguage)) {
    _locale = browserLanguage;
  }
  return _locale;
};

const allMessages = {
  en,
  de,
};
const defaultLocale = getLocaleByBrowser();

export const i18nProvider = polyglotI18nProvider(
  locale => allMessages[locale],
  defaultLocale, { allowMissing: true },
);

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const changeLocale = (newLocale) => {
  i18nProvider.changeLocale(newLocale)
    .then(() => {
      const event = new CustomEvent(CHANGE_LOCALE, {
        detail: { newLocale },
      });
      document.dispatchEvent(event);
    });
};
