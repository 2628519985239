import { merge } from "lodash";
import englishMessages from "ra-language-english";

// https://marmelab.com/react-admin/doc/3.0/Translation.html#translation-messages
const raOverrides = {
  ra: {
    auth: {
      auth_check_error: "Authentication failed. Please log in.",
    },
    input: {
      references: {
        single_missing: "",
      },
    },
  },
};

const resourcesOverrides = {
  resources: {
    users: {
      fields: {
        categories: "Categories",
        email: "Email",
        familyName: "Lastname",
        givenName: "Firstname",
        hasAllCategories: "All Categories",
        hasAllManufacturers: "All Manufacturers",
        manufacturers: "Manufacturers",
        organizationName: "Company",
        premium: "Premium",
        plainPassword: "Password",
        passwordConfirmation: "Confirm Password",
        active: "Active",
        userAccount: "Account",
        isAdmin: "Admin",
      },
    },
    accounts: {
      fields: {
        categories: "Categories",
        active: "Active",
        "servicePackage.title": "Service Package",
        "servicePackage.amountUsers": "Amount Users",
        users: "Users",
        organizationName: "Company",
        accountManufacturers: "Manufacturers",
        accountCategories: "Categories",
        accountServicePackageId: "Service-Package",
        hasAllManufacturers: "All Manufacturers",
      },
    },
    properties: {
      fields: {
        name: "Name",
        filterActive: "display as filter",
        premium: "Premium",
        type: "Type",
        category: "Category",
        language: "Language",
        sortOrder: "Sort Order",
      },
    },
    "import-files": {
      fields: {
        fileName: "Filename",
        dateCreated: "Upload Date",
        fileSize: "Filesize in kB",
        files: "CSV Files",
      },
    },
    "user-logs": {
      fields: {
        userName: "User",
        organizationName: "Company",
        premium: "Premium",
        familyName: "Lastname",
        givenName: "Firstname",
        loginTime: "Login Date",
        deviceType: "Device Type",
        email: "Email",
        browserName: "Browser",
        operatingSystem: "Operating System",
      },
    },
    images: {
      fields: {
        images: "Image Files",
        uploadDate: "Upload Date",
        imageName: "Filename",
        link: "Image",
      },
    },
    manufacturers: {},
    categories: {},
  },
};

const app = {
  app: {
    language: "Language",
    lang_english: "English",
    lang_german: "German",
    editOrder: "Edit order",
    users: "Users",
    accounts: "Accounts",
    firstName: "First name",
    lastName: "Last name",
    email: "E-Mail",
    properties: "Properties",
    "import-files": "Import Files",
    "image-upload": "Image Upload",
    "user-logs": "User Logins Overview",
    startFileImport: "Start File Import",
    fileUpload: "Upload File",
    uploadImage: "Upload New Images",
    importSuccess: "Import successful",
    importPartialSuccess:
      "Import partially successful. The following files could not be imported: %{failedImports}",
    importFailure: "Import failed. Please try again",
    importFailureNoFiles: "Import failed. Import Folder is empty.",
    deleteFileConfirmTitle: "Are you sure you want to delete this file?",
    deleteFileConfirmImageTitle:
      "Are you sure you want to delete this image file?",
    successDeleting: "File deleted.",
    errorDeleting: "Could not delete file.",
    userName: "User",
    loginTime: "Login Time",
    deviceType: "Device Type",
    browserName: "Browser",
    operatingSystem: "Operating System",
    passwordLengthError: "Password must be at least 8 characters",
    passwordsNoMatch: "Does not match the password",
    manufacturers: "Manufacturers",
    categories: "Categories",
    emptyImageTitle: "No images uploaded yet.",
    emptyImageLabel: "Upload Images",
    emptyFileUploadTitle: "No CSV files uploaded yet.",
    emptyFileUploadLabel: "Upload CSV files",
  },
};

export const en = merge(
  {},
  englishMessages,
  raOverrides,
  resourcesOverrides,
  app
);
