import React from 'react';
import { Pagination } from 'react-admin';

export const HighPagination = props => (
  <Pagination
    {...props}
    rowsPerPageOptions={[75, 100, 500]}
  />
);

