export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REQUEST_TOKEN';
export const USER_ID = 'USER_ID';

export const api = {
  defaultHeaders: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
  baseUrl: `${process.env.REACT_APP_HOST}`,
  async login(username, password) {
    const _api = this;
    const result = fetch(`${_api.baseUrl}/oauth/v2/token`, {
      method: 'POST',
      headers: {
        ..._api.defaultHeaders,
      },
      body: JSON.stringify({
        grant_type: 'password',
        client_id: `${process.env.REACT_APP_OAUTH_CLIENT}`,
        client_secret: `${process.env.REACT_APP_OAUTH_SECRET}`,
        username,
        password,
        scope: 'admin',
      }),
    })
      .then(response => (
        response.ok
          ? response.json()
          : Promise.reject(response)
      ))
      .then((data) => {
        const {
          access_token: accessToken,
          // refresh_token: refreshToken,
          currentUser,
        } = data;

        _api.checkAndSetAuth(accessToken, currentUser.id);
        return Promise.resolve(data);
      });
    return result;
  },
  logout() {
    this.defaultHeaders.Authorization = '';
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(USER_ID);
  },
  checkAndSetAuth(accessToken, userId) {
    if (accessToken && userId) {
      // TODO use ServiceWorker Cache if available, fallback localstorage
      // also for the getters etc
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(USER_ID, userId);
    }

    const _accessToken = accessToken
      || localStorage.getItem(ACCESS_TOKEN);

    if (_accessToken) {
      this.defaultHeaders.Authorization = `Bearer ${_accessToken}`;
    }
    return !!_accessToken;
  },
};

