import React from 'react';
import {
  ImageInput,
  Create,
  ImageField,
  SimpleForm,
  required,
} from 'react-admin';

export const ImageUploadCreate = props => (
  <Create {...props} style={{ maxWidth: '50%' }}>
    <SimpleForm >
      <ImageInput multiple source="images" accept="image/*" validate={required()}>
        <ImageField source="src" title="title" style={{ paddingTop: 16, paddingRight: 16 }} />
      </ImageInput>
    </SimpleForm>
  </Create>
);
