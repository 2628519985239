/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  UserMenu,
} from 'react-admin';
import {
  Button,
} from '@material-ui/core'; import {
  i18nProvider,
  changeLocale,
} from '../../../services/i18nProvider';
import './CustomAppBar.css';

const useStyles = makeStyles({
  userMenuList: {
    minWidth: 200,
    listStyleType: 'none',
    paddingLeft: 0,
  },
  userMenuTitle: {
    fontWeight: '700',
    paddingLeft: 56,
    color: '#0000008A',
    fontSize: '1rem',
    justifyContent: 'flex-start',
    marginBottom: 8,
  },
  languageLabel: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    color: '#0000008A',
    fontSize: '1rem',
    paddingLeft: 56,
    width: '100%',
    borderRadius: 0,
  },
  languageLabelSelected: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingLeft: 56,
    fontSize: '1rem',
    textTransform: 'none',
    backgroundColor: '#2196F3',
    color: 'white',
    borderRadius: 0,
  },
});

const CustomUserMenu = (props) => {
  const {
    translate: t,
    getLocale,
  } = i18nProvider;
  const locale = getLocale();

  const handleLocaleBtn = (event) => {
    const nextLocale = event.currentTarget.name;
    changeLocale(nextLocale);
  };

  const locales = {
    en: (<span>{t('app.lang_english')}</span>),
    de: (<span>{t('app.lang_german')}</span>),
  };

  const classes = useStyles();

  return (
    <UserMenu {...props}>
      <ul className={classes.userMenuList}>
        <li>
          <div className={classes.userMenuTitle}>{t('app.language')}</div>
        </li>
        {Object.keys(locales).map(currLocale => (
          <li key={currLocale}>
            <Button
              className={
                currLocale === locale ? classes.languageLabelSelected : classes.languageLabel
              }
              onClick={handleLocaleBtn}
              name={currLocale}
            >
              {locales[currLocale]}
            </Button>
          </li>
        ))}
      </ul>
    </UserMenu>
  );
};

export const CustomAppBar = props => (
  <AppBar
    {...props}
    className="CustomAppBar"
    userMenu={<CustomUserMenu />}
  >
    <div className="CustomAppBar-spacer" />
  </AppBar>
);
