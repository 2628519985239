import {
  api,
} from './api';

export const authProvider = {
  login: (params) => {
    const { username, password } = params;
    return api.login(username, password);
  },
  logout: () => {
    api.logout();
    return Promise.resolve();
  },
  checkAuth: () => (
    api.checkAndSetAuth()
      ? Promise.resolve()
      : Promise.reject()
  ),
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      api.logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // https://marmelab.com/react-admin/Authorization.html
  getPermissions: () => Promise.resolve(),
};
