import React from 'react';
import { version } from '../../package.json';
import { RootContext } from './RootContext';
import { AdminPage } from './pages/AdminPage/AdminPage';
import './App.css';

export const OVERVIEW = 'overview';

export const App = () => (
  <RootContext>
    <div className="App">
      <div
        className="app-info"
        style={{ display: 'none' }}
        data-version={version}
      />
      <AdminPage />

    </div>
  </RootContext>
);
