import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  useDataProvider,
  useNotify,
} from 'react-admin';
import _ from 'lodash';
import { Button } from '@material-ui/core/';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PropTypes from 'prop-types';
import { i18nProvider } from '../../../services/i18nProvider';
import { CustomActions } from '../CustomActions/CustomActions';
import DeleteButtonWithConfirmation from '../CustomButtons/DeleteButtonWithConfirmation';
import { IMPORT_FILES } from '../../../services/dataProvider';
import { Empty } from '../CustomEmptyPage/Empty';

const ImportFileActions = (props) => {
  const { translate: t } = i18nProvider;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = React.useState(false);
  const createLabel = t('app.fileUpload');
  return (
    <CustomActions createLabel={createLabel} {...props}>
      <Button
        startIcon={<PlayCircleOutlineIcon />}
        disabled={loading}
        onClick={() => {
          setLoading(true);
          dataProvider.import(IMPORT_FILES)
            .then((response) => {
              let notification = t('app.importSuccess');
              const importFailedFiles = _.get(response, 'data.failedImports', []);
              if (!_.isEmpty(importFailedFiles)) {
                const failedImports = importFailedFiles.join();
                notification = t('app.importPartialSuccess', { failedImports });
              }
              notify(notification, 'success');
              setLoading(false);
          })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error.status);
              const notification = t('app.importFailure');
              // todo: once the api has defined error statuses handle them here
              // if (error.status === 400) {
              //   notification = t('app.importFailureNoFiles');
              // }
              notify(notification, 'warning');
              setLoading(false);
            });
        }}
      >
        {t('app.startFileImport')}
      </Button>
    </CustomActions>
  );
};

export const ImportFileList = (props) => {
  const { translate: t } = i18nProvider;
  return (
    <List
      {...props}
      bulkActionButtons={false}
      empty={<Empty {...props} title={t('app.emptyFileUploadTitle')} label={t('app.emptyFileUploadLabel')} />}
      actions={<ImportFileActions {...props} canCreate />}
    >
      <Datagrid>
        <TextField source="fileName" />
        <DateField source="dateCreated" showTime />
        <TextField source="fileSize" />
        <DeleteButtonWithConfirmation
          dialogTitle={t('app.deleteFileConfirmTitle')}
        />
      </Datagrid>
    </List>
  );
};

ImportFileList.propTypes = {
  basePath: PropTypes.string.isRequired,
};
