import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import _ from "lodash";
import { ACCESS_TOKEN, api } from "./api";
import {
  convertFileToBase64,
  parseAccountIn,
  parseAccountOut,
} from "./parsers";

export const USERS = "users";
export const ACCOUNTS = "accounts";
export const PROPERTIES = "properties";
export const IMPORT_FILES = "import-files";
export const IMAGE_UPLOAD = "images";
export const USER_LOGS = "user-logs";
export const CATEGORIES = "categories";
export const MANUFACTURERS = "manufacturers";
export const SERVICE_PACKAGES = "service-packages";

/**
 * the documented default httpClient looks like this:
 * https://marmelab.com/react-admin/Authentication.html
 *
 *
 const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};


 * we have the default headers and token in api.js
 * for consistent cross-app-usage
 *  */
const httpClient = (url, options = {}) => {
  const allOptions = {
    ...options,
    headers: new Headers({
      ...api.defaultHeaders,
      ...options.headers,
    }),
  };
  if (options.body instanceof FormData) {
    const optionsFormData = {
      ...options,
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
      }),
    };
    return fetchUtils.fetchJson(url, optionsFormData);
  }
  return fetchUtils.fetchJson(url, allOptions);
};
const apiUrl = `${api.baseUrl}/api`;

export const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const userId = _.get(params.filter, "userId", false);

    /**
     * properties have to be sorted by sortOrder but we cannot achieve this with a sort param
     * in the List as this would allow the user to also sort desc on column click
     * which would be unwanted behavior here
     */
    if (resource === PROPERTIES) {
      field = "sortOrder";
      order = "ASC";
    }
    const query = {
      sort: field,
      order,
      offset: JSON.stringify((page - 1) * perPage),
      limit: JSON.stringify(perPage),
      ...params.filter,
    };
    let url = `${apiUrl}/${resource}?${stringify(query)}`;

    if (resource === IMPORT_FILES) {
      const validSortByColumns = ["fileName", "fileSize", "dateCreated"];
      const columnToSortBy = validSortByColumns.includes(params.sort.field)
        ? params.sort.field
        : null;

      if (columnToSortBy) {
        url = `${apiUrl}/${resource}?${stringify({
          sort: columnToSortBy,
          order: params.sort.order.toLowerCase(),
        })}`;
      } else {
        url = `${apiUrl}/${resource}`;
      }

      return httpClient(url).then((response) => ({
        data: response.json,
        total: response.json.length,
      }));
    }
    if (resource === USER_LOGS && !userId) {
      url = `${apiUrl}/users?${stringify(query)}`;
    }
    if (resource === USER_LOGS && userId) {
      url = `${apiUrl}/users/${userId}/logins?${stringify(query)}`;
    }

    if (resource === ACCOUNTS) {
      const queryObject = {
        offset: JSON.stringify((page - 1) * perPage),
        limit: JSON.stringify(perPage),
      };
      url = `${apiUrl}/${resource}?${stringify(queryObject)}`;
    }

    if (resource === CATEGORIES) {
      url = `${apiUrl}/categories`;
      return httpClient(url).then(({ json }) => ({
        data: json.data,
      }));
    }

    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
        total: json.metadata.totalCount,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      let parsedJson;
      switch (resource) {
        case ACCOUNTS: {
          parsedJson = parseAccountIn(json);
          break;
        }
        default:
          parsedJson = json;
          break;
      }

      return { data: parsedJson };
    }),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource, params) => {
    let parsedJson;
    switch (resource) {
      case ACCOUNTS: {
        parsedJson = parseAccountOut(params.data);
        break;
      }
      default:
        parsedJson = params.data;
        break;
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(parsedJson),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    if (resource === IMAGE_UPLOAD) {
      const formData = new FormData();
      formData.images = [];
      if (!_.isEmpty(params.data.images)) {
        params.data.images.map((image) => {
          const { rawFile } = image;
          const fileName = _.get(rawFile, "name", "");
          return formData.append("images[]", rawFile, fileName);
        });
      }
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }

    if (resource !== IMPORT_FILES || !params.data.files) {
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }

    const newFiles = params.data.files.filter((p) => p.rawFile instanceof File);
    const formerFiles = params.data.files.filter(
      (p) => !(p.rawFile instanceof File)
    );

    return Promise.all(newFiles.map(convertFileToBase64))
      .then((base64Files) =>
        base64Files.map((file64) => ({
          src: file64.src,
          title: file64.title,
        }))
      )
      .then((transformedNewFiles) => {
        const data = {
          ...params.data,
          files: [...transformedNewFiles, ...formerFiles],
        };
        return httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          body: JSON.stringify(data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }));
      });
  },

  import: (resource) =>
    httpClient(`${apiUrl}/${resource}/import`, {
      method: "POST",
    }).then(({ json }) => ({
      data: { failedImports: json.failedImports },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};
