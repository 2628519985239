import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { customTheme } from './theme/custom-theme';
import './main.css';

const propTypes = {
  children: PropTypes.node,
};
const defaultProps = {
  children: null,
};

export const RootContext = ({ children }) => (
  <div className="RootContext">
    <CssBaseline />
    <MuiThemeProvider theme={customTheme}>
      {children}
    </MuiThemeProvider>
  </div>
);
RootContext.propTypes = propTypes;
RootContext.defaultProps = defaultProps;
export default RootContext;
