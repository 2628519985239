import React from "react";
import PropTypes from "prop-types";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
} from "react-admin";

const required = ({ value, message = "Required" }) =>
  value ? undefined : message;

const checkPassword = ({ plainPassword, errors }) => {
  const pwError = "app.passwordLengthError";
  if (plainPassword && plainPassword.length < 8) {
    errors.plainPassword = [pwError];
  }
};

const checkPwMatch = ({ plainPassword, passwordConfirmation, errors }) => {
  const pwMatchError = "app.passwordsNoMatch";
  if (plainPassword && plainPassword !== passwordConfirmation) {
    errors.passwordConfirmation = [pwMatchError];
  }
};

const validateUserEdit = (values) => {
  const { email, plainPassword, passwordConfirmation } = values;

  const errors = {
    email: required({ value: email }),
  };

  checkPassword({ plainPassword, errors });

  checkPwMatch({ plainPassword, passwordConfirmation, errors });

  return errors;
};

const UserTitle = ({ record }) => (
  <span>
    Kunde: {record ? `"${record.givenName} ${record.familyName}"` : "–"}
  </span>
);
UserTitle.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};
UserTitle.defaultProps = {
  record: null,
};

export const UserEdit = (props) => {
  return (
    <Edit
      undoable={false}
      title={<UserTitle record={props.record} />}
      {...props}
    >
      <SimpleForm redirect={false} undoable={false} validate={validateUserEdit}>
        <TextInput source="givenName" />
        <TextInput source="familyName" />
        <TextInput source="email" type="email" />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => (
            <ReferenceInput
              perPage={250}
              reference="accounts"
              source="accountId"
              {...rest}
            >
              <SelectInput optionText="organizationName" />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <TextInput source="plainPassword" type="password" />
        <TextInput source="passwordConfirmation" type="password" />
        <BooleanInput source="isAdmin" />
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};
UserEdit.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};
UserEdit.defaultProps = {
  record: null,
};

const validateUserCreation = (values) => {
  const { email, plainPassword, passwordConfirmation } = values;

  const errors = {
    email: required({ value: email }),
    plainPassword: required({ value: plainPassword }),
    confirmPassword: required({ value: passwordConfirmation }),
  };

  checkPassword({ plainPassword, errors });

  checkPwMatch({ plainPassword, passwordConfirmation, errors });

  return errors;
};

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={validateUserCreation}>
      <TextInput source="givenName" />
      <TextInput source="familyName" />
      <TextInput source="email" type="email" />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => (
          <ReferenceInput
            perPage={250}
            reference="accounts"
            source="accountId"
            {...rest}
          >
            <SelectInput optionText="organizationName" />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <TextInput source="plainPassword" type="password" />
      <TextInput
        label="Confirm password"
        source="passwordConfirmation"
        type="password"
      />
      <BooleanInput source="isAdmin" />
    </SimpleForm>
  </Create>
);
