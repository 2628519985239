import React from "react";
import { List, Datagrid, TextField, EmailField } from "react-admin";
import { CustomActions } from "../CustomActions/CustomActions";

export const UserList = (props) => (
  <List {...props} actions={<CustomActions canCreate />}>
    <Datagrid rowClick="edit">
      <TextField source="givenName" />
      <TextField source="familyName" />
      <EmailField source="email" />
      <TextField source="organizationName" />
    </Datagrid>
  </List>
);

export default UserList;
