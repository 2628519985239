/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Datagrid,
  useDataProvider,
} from 'react-admin';

import {
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import _ from 'lodash';

const Row = ({
  id,
  record,
  resource,
  basePath,
  children,

  provided, // react-beautiful-dnd
}) =>
  /*
    Custom Datagrid rows:
    see https://marmelab.com/react-admin/List.html#the-datagrid-component

    with react-beautiful-dnd Draggable: "provided"
    https://github.com/atlassian/react-beautiful-dnd/blob/dec0c876424271be38f5ac0252fa5b5a881df419/docs/api/draggable.md
  */
  (
    <TableRow
      key={id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {React.Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
        </TableCell>
    ))}

      {provided.placeholder}
    </TableRow>
  );

const DraggableRow = (props) => {
  const {
    id,
    index,
    data,
    selectedIds,
    ...rest
  } = props;

  const isSelected = selectedIds.indexOf(id) >= 0;

  return (
    <Draggable
      key={id}
      draggableId={id.toString()}
      index={index}
    >
      {provided => (
        <Row
          provided={provided}
          {...rest}
          id={id}
          record={data[id]}
          selected={isSelected}
        />
      )}
    </Draggable>
  );
};

const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to;
  const item = array.splice(from, 1)[0];
  array.splice(startIndex, 0, item);
};

const arrayMove = (array, from, to) => {
  // eslint-disable-next-line no-param-reassign
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

const DroppableDatagridBody = (props) => {
  const [dataIds, setDataIds] = useState([...props.ids]);
  const [propertyData, setData] = useState({ ...props.data });
  const [initialIds, setInitialIds] = useState([]);
  const { datagrid } = props;
  const { filterValues } = datagrid;
  const [currentFilter, setCurrentFilter] = useState({});

  if (_.isEmpty(currentFilter)) {
    setCurrentFilter(datagrid.filterValues);
  }

  if (_.isEmpty(initialIds)) {
    setInitialIds(props.ids);
  }

  /** here be dragons! we are comparing if the filters have changed and if
   * we have a different data set so our data from state is only updated
   * if there were both changes
  */
  const dataEquality = _.isEqual(props.ids, initialIds);
  if (currentFilter !== datagrid.filterValues && !dataEquality) {
    setData(props.data);
    setDataIds(props.ids);
    setInitialIds(props.ids);
    setCurrentFilter(datagrid.filterValues);
  }
  const dataProvider = useDataProvider();
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { resource } = props;

    /** react-admin converts propertyData to an object and "sorts"
     * this by id but we need an array sorted by sortOrder here to calculate the new
     * sortOrder for the currentItem
     * */
    const dataArray = _.toArray(propertyData);
    dataArray.sort((a, b) => a.sortOrder - b.sortOrder);

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    // do not send anything if dropped in the same place
    if (startIndex === endIndex) {
      return;
    }
    const offset = endIndex - startIndex;
    const draggableId = parseInt(result.draggableId, 10);
    const currentItem = _.find(dataArray, ['id', draggableId]);
    const currentIndex = _.findIndex(dataIds, o => o === draggableId);
    if (endIndex === dataArray.length - 1) {
      currentItem.sortOrder = dataArray[endIndex].sortOrder + 1;
    } else if (endIndex === 0) {
      const nextItemSortOrder = dataArray[0].sortOrder - 1;
      currentItem.sortOrder = nextItemSortOrder > 0 ? nextItemSortOrder : 1;
    } else if (offset < 0) {
      currentItem.sortOrder = dataArray[(endIndex - 1)].sortOrder + 1;
    } else {
      currentItem.sortOrder = dataArray[endIndex].sortOrder;
    }
    const newIds = arrayMove(dataIds, currentIndex, endIndex);
    setDataIds(newIds);

    dataProvider.update(resource, {
      id: currentItem.id,
      data: {
        premium: currentItem.premium,
        filterActive: currentItem.filterActive,
        sortOrder: currentItem.sortOrder,
      },
    })
      .then(() => dataProvider.getList(resource, {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'sortOder', order: 'ASC' },
        filter: filterValues,
      }))
      .then(({ data }) => setData(data));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <TableBody
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {dataIds.map((id, index) => (
              <DraggableRow
                key={id}
                id={id}
                index={index}
                {...props}
              />
            ))}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};


export const DroppableDatagrid = props => (
  <Datagrid
    {...props}
    body={(<DroppableDatagridBody datagrid={props} />)}
  />
);
