import React from "react";
import isEmpty from "lodash/isEmpty";
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  required,
} from "react-admin";

const requiredCustom = ({ value, message = "ra.validation.required" }) =>
  value ? undefined : message;

const validateAccountEdit = (values) => {
  const { categories, organizationName, servicePackage } = values;

  return {
    categories: requiredCustom({ value: !isEmpty(categories) }),
    organizationName: requiredCustom({ value: organizationName }),
    servicePackage: requiredCustom({ value: servicePackage }),
  };
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const AccountEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm
      redirect={false}
      undoable={false}
      validate={validateAccountEdit}
      toolbar={<CustomToolbar />}
    >
      <TextInput source="organizationName" />
      <TextInput source="billingAddress.street" />
      <TextInput source="billingAddress.addressSuffix" />
      <TextInput source="billingAddress.postalCode" />
      <TextInput source="billingAddress.city" />
      <TextInput source="billingAddress.country" />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => (
          <ReferenceInput
            perPage={200}
            reference="service-packages"
            source="accountServicePackageId"
            {...rest}
          >
            <SelectInput validate={[required()]} optionText="description" />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <BooleanInput source="hasAllManufacturers" />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) =>
          !formData.hasAllManufacturers && (
            <ReferenceArrayInput
              perPage={200}
              reference="manufacturers"
              source="accountManufacturers"
              {...rest}
            >
              <SelectArrayInput validate={[required()]} />
            </ReferenceArrayInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          const optionRenderer = (choice) => {
            let language = "de";
            if (choice.language === "en_US") {
              language = "en";
            }
            return `${choice.name} (${language})`;
          };
          return (
            <ReferenceArrayInput
              perPage={200}
              allowEmpty={false}
              reference="categories"
              source="accountCategories"
              {...rest}
            >
              <SelectArrayInput
                validate={[required()]}
                optionText={optionRenderer}
              />
            </ReferenceArrayInput>
          );
        }}
      </FormDataConsumer>
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);
