/* eslint-disable react/prop-types */
import React from 'react';
import {
  CreateButton,
} from 'react-admin';
import { Toolbar } from '@material-ui/core';

export const CustomActions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  children = null,
  canCreate = false,
  createLabel = null,
}) => (
  <Toolbar>
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    {children}
    {(!!canCreate && !createLabel) && <CreateButton basePath={basePath} />}
    {(!!canCreate && !!createLabel) && <CreateButton basePath={basePath} label={createLabel} />}
  </Toolbar>
);
