import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import PropTypes from 'prop-types';
import { i18nProvider } from '../../../services/i18nProvider';
import { HighPagination } from '../CustomPagination/CustomPagination';

const exporter = (userLogs) => {
  const {
    translate: t,
    getLocale,
  } = i18nProvider;
  const locale = getLocale();
  const userLogsForExport = userLogs.map((userLog) => {
    const { id, loginTime, ...userLogForExport } = userLog; // omit id
    const date = new Date(loginTime);
    userLogForExport.loginTime = date.toLocaleString(locale);
    return userLogForExport;
  });
  jsonExport(userLogsForExport, {
    headers: ['userName', 'deviceType', 'browserName', 'operatingSystem', 'loginTime'],
    rename: [t('app.userName'), t('app.deviceType'), t('app.browserName'), t('app.operatingSystem'), t('app.loginTime')],
  }, (err, csv) => {
    downloadCSV(csv, 'user-logins');
  });
};

export const UserLogsShow = props => (
  <List
    {...props}
    filter={{ userId: props.id }}
    perPage={100}
    sort={{ field: 'loginTime', order: 'DESC' }}
    pagination={<HighPagination />}
    bulkActionButtons={false}
    exporter={exporter}
  >
    <Datagrid>
      <TextField source="userName" sortable={false} />
      <TextField source="deviceType" sortable={false} />
      <TextField source="browserName" sortable={false} />
      <TextField source="operatingSystem" sortable={false} />
      <DateField source="loginTime" showTime />
    </Datagrid>
  </List>
);

UserLogsShow.propTypes = {
  basePath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
