import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ImageField,
  Pagination,
} from 'react-admin';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { i18nProvider } from '../../../services/i18nProvider';
import DeleteButtonWithConfirmation from '../CustomButtons/DeleteButtonWithConfirmation';
import { CustomActions } from '../CustomActions/CustomActions';
import { Empty } from '../CustomEmptyPage/Empty';

const styles = {
  image: { maxHeight: '7rem' },
};

export const ImageUploadList = withStyles(styles)((props) => {
  const { translate: t } = i18nProvider;
  const { classes } = props;
  return (
    <List
      {...props}
      empty={<Empty {...props} title={t('app.emptyImageTitle')} label={t('app.emptyImageLabel')} />}
      bulkActionButtons={false}
      actions={<CustomActions canCreate createLabel={t('app.uploadImage')} />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} perPage={100} />}
    >
      <Datagrid>
        <TextField source="imageName" sortable={false} />
        <ImageField source="link" classes={classes} sortable={false} />
        <DateField source="uploadDate" showTime sortable={false} />
        <DeleteButtonWithConfirmation
          dialogTitle={t('app.deleteFileConfirmImageTitle')}
        />
      </Datagrid>
    </List>
  );
});

ImageUploadList.propTypes = {
  basePath: PropTypes.string.isRequired,
};
