import { merge } from "lodash";
import germanMessages from "ra-language-german";

// https://marmelab.com/react-admin/doc/3.0/Translation.html#translation-messages
const raOverrides = {
  ra: {
    action: {
      close: "Schließen",
      expand: "Ausklappen",
    },
    auth: {
      auth_check_error:
        "Authentifizierung fehlgeschlagen. Bitte loggen Sie sich wieder ein.",
    },
    input: {
      references: {
        single_missing: "",
      },
    },
    boolean: {
      null: "",
    },
    message: {
      details: "Details",
    },
    notification: {
      data_provider_error:
        "dataProvider Fehler. Öffnen Sie die Console für Details.",
      i18n_error:
        "Übersetzungen für die gewählte Sprache konnten nicht geladen werden.",
      logged_out:
        "Ihre Session ist abgelaufen. Bitte loggen Sie sich wieder ein.",
    },
    page: {
      loading: "Lade",
    },
  },
};

const resourcesOverrides = {
  resources: {
    users: {
      fields: {
        categories: "Kategorien",
        email: "Email",
        familyName: "Nachname",
        givenName: "Vorname",
        hasAllCategories: "Alle Kategorien",
        hasAllManufacturers: "Alle Hersteller",
        manufacturers: "Hersteller",
        organizationName: "Firma",
        premium: "Premium",
        plainPassword: "Passwort",
        passwordConfirmation: "Passwort bestätigen",
        active: "Aktiv",
        userAccount: "Account",
        isAdmin: "Admin",
      },
    },
    accounts: {
      fields: {
        categories: "Kategorien",
        active: "Aktiv",
        "servicePackage.title": "Service Paket",
        "servicePackage.amountUsers": "Anzahl Benutzer",
        users: "Benutzer",
        organizationName: "Firma",
        accountManufacturers: "Hersteller",
        accountCategories: "Kategorien",
        accountServicePackageId: "Service-Paket",
        hasAllManufacturers: "Alle Hersteller",
      },
    },
    properties: {
      fields: {
        name: "Name",
        filterActive: "als Filter anzeigen",
        premium: "Premium",
        type: "Typ",
        category: "Kategorie",
        language: "Sprache",
        sortOrder: "Reihenfolge",
      },
    },
    "import-files": {
      fields: {
        fileName: "Dateiname",
        dateCreated: "Uploadzeitpunkt",
        fileSize: "Dateigröße in kB",
        files: "CSV Dateien",
      },
    },
    "user-logs": {
      fields: {
        userName: "User",
        organizationName: "Firma",
        premium: "Premium",
        familyName: "Nachname",
        givenName: "Vorname",
        loginTime: "Loginzeitpunkt",
        deviceType: "Gerätetyp",
        email: "Email",
        browserName: "Browser",
        operatingSystem: "Betriebssystem",
      },
    },
    images: {
      fields: {
        images: "Bild Dateien",
        uploadDate: "Uploadzeitpunkt",
        imageName: "Dateiname",
        link: "Bild",
      },
    },
    manufacturers: {},
    categories: {},
  },
};

const app = {
  app: {
    language: "Sprache",
    lang_english: "Englisch",
    lang_german: "Deutsch",
    editOrder: "Reihenfolge bearbeiten",
    users: "Benutzer",
    accounts: "Accounts",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    properties: "Eigenschaften",
    "image-upload": "Bilder Upload",
    "import-files": "Datei-Import/Upload",
    "user-logs": "Übersicht Userlogins",
    startFileImport: "Import starten",
    fileUpload: "Datei Upload",
    uploadImage: "Neue Bilder hochladen",
    importSuccess: "Import erfolgreich",
    importPartialSuccess:
      "Import teilweise erfolgreich. Die Folgenden Datein konnten nicht importiert werden: %{failedImports}",
    importFailure: "Import fehlgeschlagen. Bitte versuchen Sie es erneut.",
    importFailureNoFiles:
      "Import fehlgeschlagen. Keine CSV-Datei im Import-Ordner.",
    deleteFileConfirmTitle: "Wollen Sie die Datei wirklich löschen?",
    deleteFileConfirmImageTitle: "Wollen Sie die Bild-Datei wirklich löschen?",
    successDeleting: "Datei gelöscht.",
    errorDeleting: "Datei konnte nicht gelöscht werden",
    userName: "User",
    loginTime: "Loginzeitpunkt",
    deviceType: "Gerätetyp",
    browserName: "Browser",
    operatingSystem: "Betriebssystem",
    passwordLengthError: "Min. Passwortlänge: 8 Zeichen",
    passwordsNoMatch: "Passwörter stimmen nicht überein",
    manufacturers: "Hersteller",
    categories: "Kategorien",
    emptyImageTitle: "Noch keine Bilder vorhanden",
    emptyImageLabel: "Bilder hochladen",
    emptyFileUploadTitle: "Noch keine CSV Dateien vorhanden",
    emptyFileUploadLabel: "CSV Dateien hochladen",
  },
};

export const de = merge(
  {},
  germanMessages,
  raOverrides,
  resourcesOverrides,
  app
);
