import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CreateButton } from 'react-admin';
import PropTypes from 'prop-types';

export const Empty = ({ basePath, label, title }) => (
  <Box textAlign="center" m={1}>
    <Typography variant="h5" paragrap style={{ marginTop: 50 }} >
      {title}
    </Typography>
    <CreateButton basePath={basePath} label={label} />
  </Box>
);

Empty.propTypes = {
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
