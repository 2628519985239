import omit from "lodash/omit";

export const parseAccountIn = (account) => {
  const parsedAccount = JSON.parse(JSON.stringify(account));
  const {
    manufacturers,
    categories,
    servicePackage,
    organizationName,
  } = account;
  parsedAccount.accountManufacturers = manufacturers.map((item) => item.id);
  parsedAccount.accountCategories = categories.map((item) => item.id);
  parsedAccount.accountServicePackageId = servicePackage.id;
  parsedAccount.company = organizationName;
  return parsedAccount;
};
export const parseAccountOut = (account) => {
  let parsedAccount = JSON.parse(JSON.stringify(account));
  const {
    accountManufacturers,
    accountCategories,
    accountServicePackageId,
    hasAllManufacturers,
    organizationName,
    active,
  } = parsedAccount;

  parsedAccount.manufacturers = hasAllManufacturers
    ? []
    : accountManufacturers.map((id) => ({ id }));
  parsedAccount.categories = accountCategories.map((id) => id);
  parsedAccount.servicePackageId = accountServicePackageId;
  parsedAccount.company = organizationName;
  parsedAccount.isActive = active;
  parsedAccount = omit(parsedAccount, [
    "accountManufacturers",
    "accountCategories",
    "accountServicePackageId",
  ]);
  return parsedAccount;
};

export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    // remove the Data-URL declaration
    reader.onload = () =>
      resolve({
        src: reader.result.substr(reader.result.indexOf(",") + 1),
        title: file.title,
      });
    reader.onerror = reject;
  });
